import { Button, Form, Input, Modal, Select, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthButton from '../../../components/auth/authButton';
import { ApiActions } from '../../../models/event';
import { Role } from '../../../models/role';
import { AppRole, User } from '../../../models/user';
import {
  getAllGroups,
  getApplications,
  inviteUser,
  updateUser,
} from '../../../services/dataService';
import { setSelectionList } from '../../../store/reducers/applicationReducer';
import {
  appSelector,
  AppSelectorState,
} from '../../../store/selectors/appSelector';
import { AppState } from '../../../store/state.model';
import { NewGroupModal } from '../../groups/newGroup';
import { AppRoles } from '../role';
const { Option } = Select;

export interface UserInviteProps {
  show: boolean;
  toggleShow: any;
  formValue: User | null;
}

export const UserInviteModal = (props: UserInviteProps) => {
  const { show, toggleShow, formValue } = props;
  const [form] = useForm();
  const [isUpdate, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const [groupModalVisible, setGroupModalVisible] = useState(false);

  // global state
  const {
    event,
    groups,
    applications,
    appSelectionList,
    organization,
    loading,
  } = useSelector<AppState, AppSelectorState>(appSelector);

  // hooks
  useEffect(() => {
    dispatch<any>(getAllGroups(false));
    dispatch<any>(getApplications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (event != null && event.action === ApiActions.UserInvite) {
      toggleShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    setUpdate(false);
    if (show) {
      form.resetFields();

      if (formValue) {
        form.setFieldsValue({
          id: formValue.id,
          email: formValue.email,
          groups:
            formValue.memberOf && formValue.memberOf.length > 0
              ? formValue.memberOf.map((a) => a.id)
              : [],
          roles:
            formValue.appRoleAssignments &&
              formValue.appRoleAssignments.length > 0
              ? formValue.appRoleAssignments.map((a) => a.appRoleId)
              : [],
        });
        setUpdate(true);
      }

      let apps = applications.filter(
        (a: any) => a.appRoles && a.appRoles.length > 0
      );
      //the next line leaves only craftos roles:
      apps = apps.filter((a) => a.appOwnerOrganizationId === organization);

      dispatch<any>(setSelectionList(apps));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmitUser = async () => {
    try {
      form.submit();
      await form.validateFields();
    } catch (errors) {
      return;
    }

    const param = form.getFieldsValue();
    let appRoles: AppRole[] = [];

    if (!isUpdate) {
      if (!param?.roles) param.roles = appRoles;
      if (!param?.groups) param.groups = [];

      dispatch<any>(inviteUser(param));
    } else {
      if (param.roles && param.roles.length > 0) {
        for (let i = 0; i < param.roles.length; i++) {
          const role = param.roles[i];
          const app = appSelectionList.filter(
            (a) =>
              a.appRoles.filter((b) => b.id === role) != null &&
              a.appRoles.filter((b) => b.id === role).length > 0
          );
          if (app && app[0]) {
            appRoles.push({ appId: app[0].id, roleId: role });
          }
        }
      }
      param.roles = appRoles;
      if (formValue?.id) dispatch<any>(updateUser(formValue.id, param));
    }
  };

  const updateRoles = (newRoles: string[]) => {
    form.setFieldValue('roles', newRoles)
  }

  const onToggleGroupModal = () => setGroupModalVisible((p) => !p);
  return (
    <>
      <Modal
        width={1000}
        title={!isUpdate ? 'Invite User' : 'Edit User'}
        visible={show}
        onCancel={toggleShow}
        maskClosable={false}
        footer={[
          <Button key='back' onClick={toggleShow}>
            Cancel
          </Button>,
          <AuthButton
            key='submit'
            type='primary'
            onClick={onSubmitUser}
            roles={[Role.Admin, Role.SuperAdmin]}
          >
            {isUpdate ? 'Update' : 'Invite'}
          </AuthButton>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            labelCol={{
              span: 2,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  pattern: new RegExp(
                    /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/
                  ),
                  message: 'Not a valid email',
                },
              ]}
            >
              <Input placeholder='User Email' disabled={isUpdate} />
            </Form.Item>

            <Form.Item label='Groups' name='groups'>
              <Select
                showSearch
                allowClear
                placeholder='Select groups'
                optionFilterProp='children'
                mode='multiple'
                notFoundContent={
                  <>
                    <span>
                      <span> Not found. Create a </span>
                      <Typography.Link
                        style={{
                          marginRight: 8,
                        }}
                        onClick={(e) => onToggleGroupModal()}
                      >
                        New Group
                      </Typography.Link>
                    </span>
                  </>
                }
              >
                {groups.map((g) => (
                  <Option key={g.id}>
                    {g.description} ({g.displayName})
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <AppRoles form={form} updateRoles={updateRoles} />
          </Form>
        </Spin>
      </Modal>
      <NewGroupModal
        show={groupModalVisible}
        toggleShow={onToggleGroupModal}
        formValue={null}
      />
    </>
  );
};
